import { createStore } from 'vuex'
import netlifyIdentity from 'netlify-identity-widget';
import $ from "jquery";

import axios from "axios"


export default createStore({
  state: {
    userInfo: {},
    uid: "",
    alerts: [],
    intents: [],
    message:"",
    chat: [],
    tickets: [],
    chatTicket: "",
    sinceLast: 0,
    failcount: 1,
    loaded: false,
    inventory: [],
    maxstock: 0
  },
  mutations: {
    uploadServ(state,doc){
      axios.post(".netlify/functions/set-serv",{
        doc: doc
      },{
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        }
      }).then(res=>{
        state.userInfo = res.data.data
        state.alerts.push(["Servizi Aggiornati con successo","success"])
      })
    },
    setMail(state,persMailContact){
      axios.post(".netlify/functions/set-mail",{
        persMailContact: persMailContact,
      },{
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        }
      }).then(res=>{
        state.userInfo = res.data.data
        state.alerts.push(["Contatto aggiornato con successo","success"])
      })
    },
    setPhone(state,phoneContact){
      axios.post(".netlify/functions/set-phone",{
        phoneContact: phoneContact,
      },{
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        }
      }).then(res=>{
        state.userInfo = res.data.data
        state.alerts.push(["Contatto aggiornato con successo","success"])
      })
    },
    setDomain(state,domain){
      axios.post(".netlify/functions/set-domain",{
        domain: domain
      },{
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        }
      }).then(res=>{
        state.userInfo = res.data.data
        state.alerts.push(["Dominio autorizzato con successo","success"])
      })
    },
    setWingCode(state,code){
      axios.post(".netlify/functions/set-code",{
        code: code
      },{
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        }
      }).then(res=>{
        state.userInfo = res.data.data
        state.alerts.push(["Codice inserito con successo","success"])
      })
    },
    setTicket(state,id){
      state.chatTicket = id
    },
    timerUpdate(state){
      state.sinceLast = state.sinceLast+1
      if(state.sinceLast > state.failcount && state.chatTicket != ""){
        state.sinceLast = 0
        axios.get(".netlify/functions/get-ticket?id="+state.chatTicket, {
            headers: {
              Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
            }
          }
        ).then(res=>{
          let conv = state.tickets.find(el => el.ref["@ref"].id == state.chatTicket).data.chat
          if(conv.length == res.data.data.chat.length){
            if(state.failcount < 30){
              state.failcount = state.failcount*2
            }
          }else{
            state.failcount = 1
          }
          state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatTicket)].data.chat = res.data.data.chat
        })
      }
    },
    getTickets(state){
      axios.get(".netlify/functions/get-tickets", {
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
      }
    ).then(res=>{
      state.tickets = res.data
      if(res.data.length > 0){
        state.chatTicket = res.data[0].ref["@ref"].id
      }
      state.loaded = true
    })
    },
    sendMsg(state,msg){
      let doc = [
        {
          type: "sent",
          msg: msg
        }
      ]
      if(state.tickets.length == 0 || state.tickets[0].data.open == false){
        doc.unshift({
          type: 'update',
          msg: "Hai aperto un nuovo ticket"
        })
        axios.post('.netlify/functions/create-ticket', JSON.stringify({chat: doc}), {
          headers: {
            Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
          }
        }).then(res=>{
          state.tickets.unshift(res.data)
          state.chatTicket = res.data.ref["@ref"].id
          state.sinceLast = 0
        })
      }else{
        let conv = state.tickets.find(el => el.ref["@ref"].id == state.chatTicket).data.chat
        if(conv.type == 'askend'){
          doc.unshift({
            type: "enddeclined",
            msg: "Chiusura ticket rifiutata"
          })
        }
        let docfin = JSON.parse(JSON.stringify(conv))
        docfin = docfin.concat(doc)
        axios.post('.netlify/functions/update-ticket', JSON.stringify({id: state.chatTicket, chat: docfin}), {
            headers: {
              Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
            }
        }).then(res=>{
          state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatTicket)].data.chat = res.data.data.chat
          state.sinceLast = 0
        })
      }
      
    },
    approveend(state){
      let doc = [
        {
          type: "endapproved",
          msg: "Ticket chiuso"
        }
      ]
      let conv = state.tickets.find(el => el.ref["@ref"].id == state.chatTicket).data.chat
      let docfin = JSON.parse(JSON.stringify(conv))
      docfin.push(doc[0])
      axios.post('.netlify/functions/update-ticket', JSON.stringify({id: state.chatTicket, chat: docfin}), {
          headers: {
            Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
          }
      }).then(res=>{
        state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatTicket)].data.chat = res.data.data.chat
        state.sinceLast = 0
      })
    },
    disapproveend(state){
      let doc = [
        {
          type: "enddeclined",
          msg: "Chiusura ticket rifiutata"
        }
      ]
      let conv = state.tickets.find(el => el.ref["@ref"].id == state.chatTicket).data.chat
      let docfin = JSON.parse(JSON.stringify(conv))
        docfin.push(doc[0])
        axios.post('.netlify/functions/update-ticket', JSON.stringify({id: state.chatTicket, chat: docfin}), {
            headers: {
              Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
            }
        }).then(res=>{
          state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatTicket)].data.chat = res.data.data.chat
          state.sinceLast = 0
        })
    },
    setMessage(state,msg){
      state.message = msg
    },
    addErr(state,msg){
      this.state.alerts.push([msg,"danger"])
    },
    addSucc(state,msg){
      this.state.alerts.push([msg,"success"])
    },
    uploadIntent(state,payload){
      axios.post('.netlify/functions/upload-intent', JSON.stringify(payload), {
         headers: {
            Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
         }
      }).then(res=>{
        state.intents.unshift(res.data)
        state.alerts.push(["L'intento è stato caricato con successo","success"])
      }).catch(err=>{
        console.log(err)
        state.alerts.push(["Si è verificato un errore. Contatta help@farmadoc.it per ricevere assistenza","danger"])
      })
    },
    updateIntent(state,payload){
      axios.post('.netlify/functions/update-intent', JSON.stringify(payload), {
        headers: {
           Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
     }).then(res=>{
        state.intents[state.intents.indexOf(state.intents.find(el => el.ref['@ref'].id == payload.id))].data = res.data
       state.alerts.push(["L'intento è stato aggiornato con successo","success"])
     }).catch(err=>{
       console.log(err)
       state.alerts.push(["Si è verificato un errore. Contatta help@farmadoc.it per ricevere assistenza","danger"])
     })
    },
    delete(state,id){
      axios.get(".netlify/functions/delete-intent?id="+id).then(res=>{
        state.intents.splice(state.intents.indexOf(state.intents.find(el => el.ref['@ref'].id == id)),1)
        state.alerts.push(["Intento eliminato con successo","success"])
      })
    },
    deactivate(state,payload){
      axios.post('.netlify/functions/deactivate-intent', JSON.stringify(payload), {
        headers: {
           Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
        }).then(res=>{
          state.intents[state.intents.indexOf(state.intents.find(el => el.ref['@ref'].id == payload.id))].data = res.data
          state.alerts.push(["Intento disattivato con successo","success"])
      })
    },
    getUser(state, id){
      $.ajax({
        url: ".netlify/functions/get-user-data",
        type: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
      }).then(res=>{
        let result = JSON.parse(res)

        state.uid = result.value.ref["@ref"].id

        state.userInfo = result.value.data

        let metrics = JSON.parse(localStorage.getItem("farmadoc.data"))
        metrics.push(result.metrics)
        localStorage.setItem("farmadoc.data", JSON.stringify(metrics));

      }).catch(err=>{
        state.alerts.push([JSON.parse(err.responseText).errorText,"danger"])

        let error = JSON.stringify({
          user: netlifyIdentity.currentUser().id,
          ts: Date.now(),
          err: JSON.parse(err.responseText).error,
        })
        netlifyIdentity.logout()

      })
    },
    checkLogs(){
      let data = JSON.parse(localStorage.getItem("farmadoc.data"))
      if(data.length>9){
        $.ajax({
          url: ".netlify/functions/upload-usage-metrics",
          data: JSON.stringify(data),
          type: 'POST',
          headers: {
            Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
          }
        }).then(res=>{

          localStorage.setItem("farmadoc.data",JSON.stringify([]))

          let result = JSON.parse(res)
          let metrics = JSON.parse(localStorage.getItem("farmadoc.data"))
          metrics.push(result.metrics)
          localStorage.setItem("farmadoc.data", JSON.stringify(metrics));

        })
      }
    },
    removeAlert(state, index){
      state.alerts.splice(index, 1);
    },
    setIntents(state, intents){
      state.intents = intents
    },
    setDrugs(state,el){
      state.inventory = el.drugs
      state.inventoryog = el.inventoryog
      state.maxstock = el.maxstock
    },
    updatepromo(state,doc){
      console.log(doc)
      axios.post('.netlify/functions/update-drug', JSON.stringify(doc), {
        headers: {
           Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
     }).then(res=>{
        state.inventory[doc.index] = res.data
        let cur = JSON.parse(JSON.stringify(state.inventoryog))
        cur[doc.index] = res.data
        state.inventoryog = JSON.parse(JSON.stringify(cur))
        state.alerts.push(["Promozione aggiornata","success"])
     })
    }
  },
  getters:{
    getDomain(state){
        return state.userInfo.domain.split(".")
    }
  },
  actions: {
    getIntents({ commit }){
      return new Promise((resolve, reject) => {
        axios.get(".netlify/functions/get-intents", {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
            }
          }
        ).then(res=>{
          commit("setIntents", res.data.reverse())
          setTimeout(() => {
            resolve("done")
          }, 200);
        })
      })
    },
    getDrugs({state,commit}){
      return new Promise((resolve,reject)=>{
        axios.post(".netlify/functions/get-inventory",{
          id: state.uid
        },{
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${netlifyIdentity.currentUser().token.access_token}`
          }
        }).then(res=>{
          var maxstk = res.data.reduce(function (prev, current) {
            return (prev.data.qty > current.data.qty) ? prev : current;
          });
          commit("setDrugs",{
            drugs: res.data,
            inventoryog: JSON.parse(JSON.stringify(res.data)),
            maxstock: maxstk.data.qty
          })
          setTimeout(() => {
            resolve("done")
          }, 100);
        })
      })
    }
  },
  modules: {
  }
})
