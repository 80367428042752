<template>
    <div class="container">
        <div class="d-flex align-items-center justify-content-center" v-if="histms < 200" style="position: fixed; width: 100%; height: 100%; background-color: rgba(255,255,255,0.5); left: 0; top: 0; z-index: 100000">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <br>
        <div class="row mb-5">
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input @click="swTab('farma')" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
                <label class="btn btn-outline-primary" for="btnradio1">Farmaci</label>

                <input @click="swTab('int')" type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio2">Intenti</label>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <canvas ref="chart"></canvas>
            </div>
        </div>
        <div class="row" v-show="sel == 'farma'">
            <div class="col">
                <div class="border rounded m-0">
                    <table class="table table-hover">
                        <thead class="border-bottom">
                            <th class="px-2 py-1 bg-secondary-subtle rounded-top">Farmaco</th>
                            <th class="py-1  bg-secondary-subtle rounded-top">Richieste nell'ultimo mese</th>
                            <th class="py-1  bg-secondary-subtle rounded-top">Intento più frequente</th>
                        </thead>
                        <tbody>
                            <tr v-for="el in farmaSort" @click="renderHist(el[0].ref['@ref'].id,false)">
                                <td>{{ el[0].data.name }}</td>
                                <td>{{ el[1] }}</td>
                                <td>{{ el[2] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
        <div class="row" v-show="sel == 'int'">
            <div class="col">
                <div class="border rounded m-0">
                    <table class="table table-hover">
                        <thead class="border-bottom">
                            <th class="px-2 py-1 bg-secondary-subtle rounded-top">Intento</th>
                            <th class="py-1  bg-secondary-subtle rounded-top">Richieste nell'ultimo mese</th>
                            <th class="py-1  bg-secondary-subtle rounded-top">Farmaco più suggerito</th>
                        </thead>
                        <tbody>
                            <tr v-for="el in intSort" @click="renderHist(el[0].ref['@ref'].id,true)">
                                <td>{{ el[0].data.title }}</td>
                                <td>{{ el[1] }}</td>
                                <td>{{ el[2] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            histms: 0, 
            chart: null,
            sel: "farma"
        }
    },
    mounted(){
        setInterval(() => {
            this.histms = this.histms + 1
        }, 1);
        if(this.$store.state.inventory.length == 0){
            this.$store.dispatch("getDrugs").then(()=>{
                if(this.$store.state.intents.length == 0){
                    this.$store.dispatch("getIntents").then(()=>{
                        this.hist(false,true)
                    })
                };
            })
        }
    },
    methods:{
        swTab(tab){
            let isInt = (tab == "int")
            console.log(isInt)
            if(this.histms > 200){
                this.sel = tab
                this.hist(isInt)
            }
        },
        hist(isInt,bypass){
            if(this.histms > 200 || bypass){
                this.histms = 0
                let type, og, re
                if(isInt){
                    type = "int"
                    og = "intents"
                    re = "title"
                }else{
                    type = "farma"
                    og = "inventory"
                    re = "name"
                }
                let self = this
                const timestampToDatestring = timestamp => `${new Date(timestamp).getDate()} ${['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'][new Date(timestamp).getMonth()]}`;
                let main = this.$store.state.userInfo.reqs
                let max = 0
                max = Math.max(...[...new Set(main.map(e=>e[type]))].filter(e=>e!=0).map(e=>main.filter(s=>s[type] == e).length))
                let calendar = Array.from({ length: 30 }, (_, i) => Date.now() - i * 24 * 60 * 60 * 1000);
                calendar.reverse()
                let sets = [...new Set(main.map(e=>e[type]))].filter(e=>e!=0).map(t=>{
                    let hist = calendar.map(e=>{
                        return main.filter(e=>e[type] == t).filter(s=>s.ts>e-86400000 && s.ts < e).length
                    })
                    try{
                        return {
                            label: self.$store.state[og].find(e=>e.ref['@ref'].id == t).data[re],
                            data: hist,
                            borderWidth: 1,
                        }
                    }catch(err){
                        return null
                    }
                }).filter(e=>e!=null)
                
                let data = {
                    labels: calendar.map(e=>timestampToDatestring(e)),
                    datasets: sets
                }
                if(this.chart){
                    this.chart.destroy();
                }
                const ctx = this.$refs.chart;
                this.chart = new Chart(ctx, {
                    type: 'bar',
                    data: data,
                    options: {
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true
                            }
                        }
                    }
                });
            }
        },
        renderHist(id,isInt){
            
            const timestampToDatestring = timestamp => `${new Date(timestamp).getDate()} ${['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'][new Date(timestamp).getMonth()]}`;
            let main = this.$store.state.userInfo.reqs
            let max = 0
            if(isInt){
                max = Math.max(...[...new Set(main.map(e=>e.int))].filter(e=>e!=0).map(e=>main.filter(s=>s.int == e).length))
                main = main.filter(e=>e.int == id)
            }else{
                max = Math.max(...[...new Set(main.map(e=>e.farma))].filter(e=>e!=0).map(e=>main.filter(s=>s.farma == e).length))
                main = main.filter(e=>e.farma == id)
            }
            let calendar = Array.from({ length: 30 }, (_, i) => Date.now() - i * 24 * 60 * 60 * 1000);
            calendar.reverse()
            let hist = calendar.map(e=>{
                return [timestampToDatestring(e), main.filter(s=>s.ts>e-86400000 && s.ts < e).length]
            })
            let data = {
                labels: hist.map(e=>e[0]),
                datasets: [{
                    label: 'Richieste',
                    data: hist.map(e=>e[1]),
                    borderWidth: 1,
                    backgroundColor: "#33e894"
                }]
            }
            if(this.chart){
                this.chart.destroy();
            }
            const ctx = this.$refs.chart;
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: data,
                options: {
                    scales: {
                        y: {
                            max: max
                        }
                    }
                }
            });
            
        }
    },
    computed:{
        farmaSort(){
            const inventory = this.$store.state.inventory
            const intents = this.$store.state.intents
            if(inventory.length>0 && intents.length > 0){
                const main = this.$store.state.userInfo.reqs.filter(e=>e.ts > Date.now()-2629800000)
                const farmas = [... new Set(main.map(e=>e.farma))].filter(e=>e!=0)
                const farmasInt = farmas.map(e=>{
                    return [e, main.filter(s=>s.farma == e).map(s=>s.ts)]
                })
                const farmasCalls = farmasInt.map(e=>{
                    const farma = inventory.find(s=>s.ref["@ref"].id == e[0])
                    const farmaIntents = main.filter(s=>s.farma == e[0]).map(s=>{
                        const respInt = intents.find(g=>g.ref["@ref"].id == s.int)
                        return respInt.data.title
                    })
                    const farmaIntentMap = [... new Set(farmaIntents)].map(s=>[s,farmaIntents.filter(g=>g == s).length]).sort((a,b)=>b[1]-a[1])[0][0]
                    return [farma,e[1].length,farmaIntentMap]
                })
                return farmasCalls
            }
            
        },
        intSort(){
            const inventory = this.$store.state.inventory
            const intents = this.$store.state.intents
            if(inventory.length>0 && intents.length > 0){
                const main = this.$store.state.userInfo.reqs.filter(e=>e.ts > Date.now()-2629800000)
                const farmas = [... new Set(main.map(e=>e.int))].filter(e=>e!=0)
                const farmasInt = farmas.map(e=>{
                    return [e, main.filter(s=>s.int == e).map(s=>s.ts)]
                })
                const farmasCalls = farmasInt.map(e=>{
                    const farma = intents.find(s=>s.ref["@ref"].id == e[0])
                    const farmaIntents = main.filter(s=>s.int == e[0]).map(s=>{
                        if(s.farma!=0){
                            const respInt = inventory.find(g=>g.ref["@ref"].id == s.farma)
                            return respInt.data.name
                        }else{
                            return "---"
                        }
                        
                    })
                    const farmaIntentMap = [... new Set(farmaIntents)].map(s=>[s,farmaIntents.filter(g=>g == s).length]).sort((a,b)=>b[1]-a[1])[0][0]
                    if(farma){
                        return [farma,e[1].length,farmaIntentMap]
                    }else{
                        return null
                    }
                }).filter(e=>e!=null)
                return farmasCalls
            }
            
        }
    }
}
</script>