<template>
  <div class="content px-5">
    <br /><br />
    <div class="card">
      <div class="card-header">
        <h3>Il tuo account</h3>
      </div>
      <div class="card-body">
        Nome: <b>{{ $store.state.userInfo.reference }}</b
        ><br /><br />
        Email: <b>{{ $store.state.userInfo.clientContact }}</b
        ><br /><br />
        Registrato il:
        <b>{{ date(parseInt($store.state.userInfo.creationDate)) }}</b
        ><br /><br />
        <router-link to="/support"
          ><button
            @click="
              $store.commit(
                'setMessage',
                'Vorrei modificare i dati del mio account.'
              )
            "
            class="btn btn-secondary"
          >
            <i class="bi bi-pencil-square"></i>&nbsp;Modifica i miei dati
          </button></router-link
        >
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-header">
        <h3>I tuoi recapiti</h3>
      </div>
      <div class="card-body">
        <div v-if="!editMail" class="contact-line">
          <span>
            Email: <b>{{ $store.state.userInfo.persMailContact }}</b>
          </span>
          <button @click="toggleMail()" class="btn btn-secondary">
            <i class="bi bi-pencil-square"></i>
          </button>
        </div>
        <div v-if="editMail" class="contact-line">
          <input
            v-model="$store.state.userInfo.persMailContact"
            type="text"
            class="form-control"
            placeholder="Email"
          />
          <button
            @click="this.$store.commit('setMail', $store.state.userInfo.persMailContact), toggleMail()" class="btn btn-secondary">Conferma</button>
        </div>
        <div v-if="!editPhone" class="contact-line">
          <span>
            Telefono: <b>{{ $store.state.userInfo.phoneContact }}</b>
          </span>
          <button @click="togglePhone()" class="btn btn-secondary">
            <i class="bi bi-pencil-square"></i>
          </button>
        </div>
        <div v-if="editPhone" class="contact-line">
          <input
            v-model="$store.state.userInfo.phoneContact"
            type="text"
            class="form-control"
            placeholder="Telefono"
          />
          <button
            @click="this.$store.commit('setPhone', $store.state.userInfo.phoneContact),togglePhone()" class="btn btn-secondary">Conferma</button>
        </div>
      </div>
    </div>
    <br />
    <div class="card" v-bind:class="adddom()">
      <div class="card-header">
        <div class="row">
          <div class="col"><h3>La tua app</h3></div>
          <div v-if="!$store.state.userInfo.domain" class="col text-end">
            <span v-if="$store.state.userInfo.activeSub" class="badge bg-danger"
              ><i class="bi bi-exclamation-circle"></i>&nbsp;Autorizza un
              dominio per completare la configurazione</span
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <h5>Dominio autorizzato</h5>
        <p class="text-body-tertiary">
          Inserisci il dominio del sito internet dove vuoi che la chat sia
          disponibile
        </p>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">https://www.</span>
          <input
            v-model="domain"
            type="text"
            class="form-control"
            placeholder="Dominio"
          />
          <span class="input-group-text" id="basic-addon1">.</span>
          <input
            v-model="extension"
            type="text"
            class="form-control"
            placeholder="Estensione"
          />
        </div>
        <span
          v-if="
            (domain.length > 0) &
            (extension.length > 0) &
            ($store.state.userInfo.domain !=
              domain + '.' + extension + '/' + subfolder)
          "
        >
          <button
            @click="$store.commit('setDomain', domain + '.' + extension)"
            class="btn btn-secondary"
          >
            <i class="bi bi-check-lg"></i>&nbsp;Autorizza il dominio
            "https://www.{{ domain }}.{{ extension }}"
          </button>
          <br />
        </span>
        <br />
        <span v-if="$store.state.userInfo.domain">
          <h5>Installazione nel tuo sito internet</h5>
          <p class="text-body-tertiary">
            Copia il seguente codice e incollalo nel tuo sito prima della fine
            del tag di chiusura <i>&lt;/body&gt;</i>.
          </p>
          <div class="bg-dark text-light p-3 rounded-2">
            {{ scrpt() }}
          </div>
          <br />
          <button @click="copyscrpt()" class="btn btn-secondary">
            <i class="bi bi-clipboard-check"></i>&nbsp;&nbsp;Copia il codice
          </button>
        </span>
      </div>
    </div>
    <br />
    <div class="row p-0 m-">
      <div class="col">
        <h3>Integrazioni</h3>
      </div>
      <div class="col text-end">
        <span
          v-if="!$store.state.userInfo.WingesfarCode"
          class="badge bg-danger"
          ><i class="bi bi-exclamation-circle"></i>&nbsp;Collega il tuo
          gestionale per completare la configurazione</span
        >
      </div>
    </div>
    <div v-bind:class="'card ' + addwing()">
      <div class="card-body p-0 pe-3">
        <div class="row">
          <div class="col-3 border-end p-4">
            <img
              src="https://www.cgm.com/_Resources/Persistent/d4bf879abb5523f9f7cf63e00b84c5b6f67badb9/logo-wingesfar-software-farmacia-italia-256x60.png"
              class="img-fluid rounded-start"
            />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col p-3">
                <p>
                  <strong>Wingesfar</strong><br /><span
                    class="text-body-tertiary"
                    >Collega Wingesfar con Farmadoc per fornire ai tuoi clienti
                    informazioni in tempo reale sui tuoi prodotti.</span
                  >
                </p>
                <button
                  class="btn btn-secondary"
                  @click="wingesfar.open ^= true"
                >
                  Configura&nbsp;&nbsp;<i
                    v-if="wingesfar.open == false"
                    class="bi bi-chevron-down"
                  ></i
                  ><i
                    v-if="wingesfar.open == true"
                    class="bi bi-chevron-up"
                  ></i>
                </button>
              </div>
            </div>
            <div
              class="row animate__animated animate__fadeIn"
              v-if="wingesfar.open"
            >
              <div class="col p-3 border-top">
                <h5>Codice farmacia</h5>
                <p class="text-body-tertiary">
                  Inserisci il codice attribuito alla tua farmacia nel circuito
                  Wingesfar.
                </p>
                <div class="input-group mb-3">
                  <input
                    v-model="wingesfar.code"
                    type="text"
                    class="form-control"
                    placeholder="Codice"
                  />
                  <button
                    v-if="wingesfar.code != wingesfar.og"
                    @click="
                      $store.commit('setWingCode', wingesfar.code);
                      wingesfar.og = wingesfar.code;
                    "
                    type="button"
                    class="btn btn-secondary"
                  >
                    <i class="bi bi-check-lg"></i>&nbsp;{{ wingesfar.txt }}
                  </button>
                </div>
                <br /><span v-if="wingesfar.link" v-bind:class="wingesfar.cls">
                  <h5>Indirizzo FTP</h5>
                  <p class="text-body-tertiary">
                    Inserisci questi dati per la configurazione dell'export FTP
                    su Wingesfar.
                  </p>
                  <div class="row p-3">
                    <div
                      class="col-3 bg-body-secondary fw-bold text-dark p-3 border rounded-start-2"
                    >
                      Host:
                    </div>
                    <div class="col bg-dark text-light p-3 rounded-end-2">
                      sftp://ftp.farmadoc.it
                    </div>
                  </div>
                  <div class="row p-3">
                    <div
                      class="col-3 bg-body-secondary fw-bold text-dark p-3 rounded-start-2 border"
                    >
                      Porta:
                    </div>
                    <div class="col bg-dark text-light p-3 rounded-end-2">
                      2022
                    </div>
                  </div>
                  <div class="row p-3">
                    <div
                      class="col-3 bg-body-secondary fw-bold text-dark p-3 rounded-start-2 border"
                    >
                      User:
                    </div>
                    <div class="col bg-dark text-light p-3 rounded-end-2">
                      EzwyIqPpRB
                    </div>
                  </div>
                  <div class="row p-3">
                    <div
                      class="col-3 bg-body-secondary fw-bold text-dark p-3 rounded-start-2 border"
                    >
                      Password:
                    </div>
                    <div class="col bg-dark text-light p-3 rounded-end-2">
                      iivcFjmGKA
                    </div>
                  </div>
                  <br />
                  <button @click="copyCode()" class="btn btn-secondary">
                    <i class="bi bi-clipboard-check"></i>&nbsp;&nbsp;Copia le
                    istruzioni per la configurazione
                  </button>
                  <br
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-8">
            <h3>Il tuo abbonamento</h3>
          </div>
          <div class="col-4 text-end">
            <span
              v-if="$store.state.userInfo.activeSub"
              class="badge bg-secondary"
              ><i class="bi bi-check-lg"></i>&nbsp;Attivo</span
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        Attivato il:
        <b>{{ date(parseInt($store.state.userInfo.activeSince)) }}</b
        ><br /><br />
        Scade il:
        <b>{{
          date(parseInt($store.state.userInfo.activeSince) + 2629800000)
        }}</b>
      </div>
    </div>
    <br />
    <router-link to="/support"
      ><button
        @click="
          $store.commit('setMessage', 'Vorrei reimpostare la mia password.')
        "
        class="btn btn-secondary"
      >
        Reimposta la password
      </button></router-link
    ><br /><br />
    <router-link to="/support"
      ><button
        @click="$store.commit('setMessage', 'Vorrei eliminare il mio account.')"
        class="btn btn-danger"
      >
        Elimina il mio account
      </button></router-link
    >
    <br /><br />
  </div>
</template>

<style>
@keyframes pulse {
  0% {
    outline: 10px solid rgba(220, 53, 69, 0.5);
  }
  50% {
    outline: 5px solid rgba(220, 53, 69, 0.2);
  }
  100% {
    outline: 10px solid rgba(220, 53, 69, 0.5);
  }
}

.contact-line {
  display: flex;
  width: 40%;
  justify-content: space-between;
  margin-bottom: 10px;
  line-height: 1.7rem;
  border: solid 1px #cecece;
  border-radius: 10px;
  padding: 5px 0px 0px 1rem;
}

.contact-line input {
  border: none;
  padding: 0;
  line-height: 2rem;
  margin-top: -5px;
}

.contact-line button {
  margin-top: -5px;
}

.dangercard {
  animation: pulse 2s infinite;
  border-color: rgb(220, 53, 69);
  outline: 10px solid rgba(220, 53, 69, 0.2);
}
</style>

<script>
export default {
  data() {
    return {
      editPhone: false,
      editMail: false,
      domain: this.dom(0),
      extension: this.dom(1),
      emailContact: "",
      wingesfar: {
        open: false,
        code: "",
        og: "",
        link: false,
        cls: "animate__animated animate__fadeIn animate__faster",
        txt: "conferma",
      },
    };
  },
  mounted() {
    if (this.$store.state.userInfo.WingesfarCode) {
      this.wingesfar.code = this.$store.state.userInfo.WingesfarCode;
      this.wingesfar.og = this.$store.state.userInfo.WingesfarCode;
    }
  },
  methods: {
    toggleMail() {
      this.editMail = this.editMail ? false : true;
    },
    togglePhone() {
      this.editPhone = this.editPhone ? false : true;
    },
    date(date) {
      return new Date(date).toLocaleDateString("it-IT", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    dom(i) {
      if (this.$store.state.userInfo.domain) {
        return this.$store.state.userInfo.domain.split(".")[i];
      } else {
        return "";
      }
    },
    adddom() {
      if (!this.$store.state.userInfo.domain) {
        return "dangercard animate__animated animate__pulse";
      }
    },
    addwing() {
      if (!this.$store.state.userInfo.WingesfarCode) {
        return "dangercard animate__animated animate__pulse";
      }
    },
    scrpt() {
      return `
                <script src="https://source.farmadoc.it/main.js"\>\</script\>\n
                <script\>\n\n
                farmadocInit("${this.$store.state.userInfo.privateKey}")\n
                </script\>\n\n
                <script src="https://source.farmadoc.it/bundle.js"\>\</script\>\n\n
                <script src="https://source.farmadoc.it/bundle2.js"\>\</script\>\n\n
            `;
    },
    copyscrpt() {
      navigator.clipboard.writeText(this.scrpt());
      this.$store.commit("addSucc", "Codice copiato negli appunti");
    },
    copyCode() {
      navigator.clipboard.writeText(
        "Host: sftp://ftp.farmadoc.it\nPorta: 2022\nUsername: EzwyIqPpRB\nPassword: iivcFjmGKA"
      );
      this.$store.commit("addSucc", "Istruzioni copiate negli appunti");
    },
  },
  computed: {
    Wingesfarcd() {
      return [this.wingesfar.code, this.wingesfar.og];
    },
  },
  watch: {
    domain(value) {
      let ptn = /[^a-z0-9]/g;
      if (ptn.test(value)) {
        this.domain = value.replace(/[^a-z0-9]/g, "");
      }
    },
    extension(value) {
      let ptn = /[^a-z0-9]/g;
      if (ptn.test(value)) {
        this.extension = value.replace(/[^a-z0-9]/g, "");
      }
      if (value.length > 3) {
        this.extension = value[0] + value[1] + value[2];
      }
    },
    Wingesfarcd(value) {
      console.log(value);
      if (value[0] != "" && value[1] != "") {
        this.wingesfar.txt = "Aggiorna";
        if (value[0] == value[1]) {
          this.wingesfar.link = true;
        } else {
          this.wingesfar.cls =
            "animate__animated animate__fadeOut animate__faster";
          setTimeout(() => {
            this.wingesfar.link = false;
            this.wingesfar.cls =
              "animate__animated animate__fadeIn animate__faster";
          }, 400);
        }
      } else {
        this.wingesfar.txt = "Conferma";
      }

      /* if(this.wingesfar.link){
                if(value != this.wingesfar.og){
                    console.log(value)
                }
            }else{
                if(value != this.wingesfar.og){
                    console.log(value)
                }else{
                    if(value != ""){
                        this.wingesfar.link = true
                    }
                }
            } */
    },
  },
};
</script>
