<template>

    <div class="content">
        <br>
        <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
                    <input v-model="search" @blur="loading = false" @focus="loading = true" type="text" class="form-control" placeholder="ricerca...">
                </div>
            </div>
        </div>
        <div class="row" style="max-height: 85vh; width: 100%; overflow-y: auto; position: relative;">
            <span v-if="loading" class="d-flex align-items-center justify-content-center" style="width: 100%; height: 85vh; position: absolute; background-color: rgba(255,255,255,0.5); z-index: 10000">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </span>
            <div v-if="$store.state.inventory.length == 0" class="text-center">
                <br><br>
                <p class="text-body-tertiary">Nessun farmaco disponibile. Completa l'integrazione Wingesfar.</p>
            </div>
            <div class="col-4 mb-3" v-for="(el,index) in $store.state.inventory" :key="index">
                <div class="card">
                    <div class="card-header">
                        <div style="height: 100px;" class="row d-flex align-items-center">
                            <div class="col-3 p-0" style="text-align: center;">
                                <img v-if="el.data.img.length>0" style="max-height: 100px; margin: auto; mix-blend-mode: darken; padding: -20px" class="img-fluid" v-bind:src='el.data.img' alt="">
                                <img v-if="el.data.img.length==0" class="img-fluid" src='../prod-placeholder.png' alt="">
                            </div>
                            <div class="col-9 ps-0" style="overflow-wrap: break-word;">
                                <strong>{{ el.data.name }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <p>Quantità in giacenza:</p>
                        <div class="row d-flex align-items-center">
                            <div class="col-9">
                                <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                    <div class="progress-bar" v-bind:style="'background-color:'+ qtyclass(el.data.qty/$store.state.maxstock)+'; width: '+widthget(el.data.qty / $store.state.maxstock)+'%'"></div>
                                </div>
                            </div>
                            <div class="col-3">
                                <p class="mb-0">
                                    <b>{{ el.data.qty }}</b>
                                </p>
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-2" @click="switchprm(index)">
                                <h2 v-if="!el.data.promo" class="m-0"><i class="bi bi-toggle-off"></i></h2>
                                <h2 v-if="el.data.promo" class="m-0"><i class="bi bi-toggle-on"></i></h2>
                            </div>
                            <div class="col-10">
                                <p class="m-0"><b>Info aggiuntive</b></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span v-if="el.data.promo"><textarea v-model="el.data.promodetail" type="text" placeholder="Descrizione promo" class="form-control mb-2"></textarea></span>
                                <button v-if="!matchog(el.ref['@ref'].id)" @click="uploadPromo(index,el.data.promo,el.data.promodetail,el.ref['@ref'].id)" class="btn btn-secondary"><i class="bi bi-check-lg"></i>&nbsp;Conferma</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <p class="text-body-tertiary mb-0">
                            Minsan: {{ el.data.minsan }}
                            <br>
                            <span v-if="el.data.prod != ''">Prodotto da: {{ el.data.prod }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>


export default{
    data(){
        return{
            search: "",
            searchres: [],
            loading: false,
            promoswitch:[]
        }
    },
    mounted(){
      if(this.$store.state.inventory.length == 0){
        this.$store.dispatch("getDrugs")
      }
    },
    methods:{
        widthget(val){
            if(val < 0){
                return "100"
            }else{
                return Math.pow(val,0.3)*100
            }
        },
        switchprm(el){
            this.$store.state.inventory[el].data.promo = Boolean(this.$store.state.inventory[el].data.promo ^= true)
        },
        qtyclass(val){
            if(val > 0.05){
                if(val>0.8){
                    return "#198754"
                }else{
                    return "grey"
                }
            }else{
                if(val < 0){
                    return "#dc3545"
                }else{
                    return "#ffc107"
                   
                }
                
            }
        },
        uploadPromo(i, prm,txt,el){
            let self = this
            this.$store.commit("updatepromo",{
                obj: {
                    "promo": prm,
                    "promodetail": txt
                },
                id: el,
                index: i
            })
        },
        matchog(a){
            return JSON.stringify(this.$store.state.inventory.find(el=>el.ref["@ref"].id == a)) == JSON.stringify(this.$store.state.inventoryog.find(el=>el.ref["@ref"].id == a))
        }
    },
    watch:{
        search(value){
            let self = this
            self.searchres = this.$store.state.inventory
            var regex = /^[0-9]+$/;
            if(value == ""){
                self.searchres.sort((b, a) => a.data.qty - b.data.qty);
            }else{
                if(regex.test(value)){
                    self.searchres.forEach(elem=>{
                        if(elem.data.minsan.toLowerCase().includes(value)){
                            self.searchres.splice(self.searchres.indexOf(elem),1)
                            self.searchres.unshift(elem)
                        }
                    })
                }else{
                    self.searchres.forEach(elem=>{
                        if(elem.data.name.toLowerCase().includes(value)){
                            self.searchres.splice(self.searchres.indexOf(elem),1)
                            self.searchres.unshift(elem)
                        }
                    })
                }
            }
            this.promoswitch = []
            this.loading = false
        }
    }
}

</script>