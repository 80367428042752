<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100vh;">
        <div class="text-center">
            <div class="row">
                <div class="col">
                    <h1>Benvenuto {{ $store.state.userInfo.reference }}</h1>
                    <span>Email: <strong>{{ $store.state.userInfo.clientContact }}</strong></span>
                </div>
                <!-- {{ $store.state.userInfo.calls }} -->
            </div>
            <div class="row">
                <div class="col mt-5">
                    <div class="card" v-if="this.$store.state.userInfo.calls">
                        <div class="card-header">
                            <h4 class="mb-1"><b>Il tuo utilizzo questo mese:</b></h4>
                            <p class="text-body-tertiary mb-0" style="font-size: 15px;">Numero di invocazioni della chat negli ultimi 30 giorni</p>
                        </div>
                        <div class="card-body">
                            <h2>{{ (this.$store.state.userInfo.calls.filter(e=>e>Date.now()-2629800000).length/2).toFixed(0) }}</h2>
                            <canvas ref="canv"></canvas>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        let curDate = Date.now()
        var opts = {
            angle: -0.2, // The span of the gauge arc
            lineWidth: 0.2, // The line thickness
            radiusScale: 1, // Relative radius
            pointer: {
                length: 0.6, // // Relative to gauge radius
                strokeWidth: 0.035, // The thickness
                color: '#000000' // Fill color
            },
            limitMax: false,     // If false, max value increases automatically if value > maxValue
            limitMin: false,     // If true, the min value of the gauge will be fixed
            colorStart: '#6FADCF',   // Colors
            colorStop: '#8FC0DA',    // just experiment with them
            strokeColor: '#E0E0E0',  // to see which ones work best for you
            generateGradient: true,
            highDpiSupport: true,     // High resolution support
            staticZones: [
                {strokeStyle: "#EBE223", min: 1000, max: 1300}, // Yellow
                {strokeStyle: "#33e894", min: 0, max: 1000}, // Green
                {strokeStyle: "#EB5B20", min: 1300, max: 1500}  // Red
            ],
            staticLabels: {
                font: "10px sans-serif",  // Specifies font
                labels: [0, 500, 1000, 1500],  // Print labels at these values
                color: "#000000",  // Optional: Label text color
                fractionDigits: 0  // Optional: Numerical precision. 0=round off.
            },
            renderTicks: {
                divisions: 5,
                divWidth: 1.1,
                divLength: 0.7,
                divColor: "#0000",
                subDivisions: 3,
                subLength: 0.5,
                subWidth: 0.6,
                subColor: "#ffff"
            }
        };
        var target = this.$refs.canv // your canvas element
        var gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
        gauge.maxValue = 1500; // set max gauge value
        gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
        gauge.animationSpeed = 32; // set animation speed (32 is default value)
        if(this.$store.state.userInfo.calls){
            gauge.set((this.$store.state.userInfo.calls.filter(e=>e>curDate-2629800000).length/2).toFixed(0)); // set actual value
        }   
    }
};
</script>
