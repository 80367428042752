<template>
    <br>
    <div class="container">
        <div class="row">
            <div class="col">
                <h6>Servizi offerti:</h6>
                <hr>
                <div ref="textarea" class="rounded" v-bind:style="'width: 100%; border: '+styletext()">
                    <div class="btn-group border-bottom rounded-0" style="width: 100%;" role="group">
                        <button @click="addTxt('😍')" type="button" class="btn border-end rounded-0">😍</button>
                        <button @click="addTxt('😄')" type="button" class="btn border-end rounded-0">😄</button>
                        <button @click="addTxt('😎')" type="button" class="btn border-end rounded-0">😎</button>
                        <button @click="addTxt('💊')" type="button" class="btn border-end rounded-0">💊</button>
                        <button @click="addTxt('👨‍⚕️')" type="button" class="btn border-end rounded-0">👨‍⚕️</button>
                        <button @click="addTxt('👩‍⚕️')" type="button" class="btn rounded-0">👩‍⚕️</button>
                    </div>
                    <div class="btn-group border-bottom rounded-0" style="width: 100%;" role="group">
                        <button @click="makeBold(); bold^=true" type="button" v-bind:class="'btn border-end rounded-0'+check(bold)"><i class="bi bi-type-bold"></i></button>
                        <button @click="makeItalic(); italic^=true" type="button" v-bind:class="'btn border-end rounded-0'+check(italic)"><i class="bi bi-type-italic"></i></button>
                        <button @click="erase()" type="button" class="btn rounded-0"><i class="bi bi-trash3"></i></button>
                    </div>
                    <div id="editor" ref="editor" style="min-height: 400px; padding: 20px;" contenteditable="true"></div>
                </div>
                <br>
                <br>
                <button v-if="!exists" @click="uploadServ()" class="btn btn-primary">Aggiorna <i class="bi bi-check-lg"></i></button>
            </div>
        </div>
        
    </div>

</template>

<style>
    #editor:focus {
        outline: none;
    }
</style>

<script>

    export default{
        data(){
            return{
                bold: 0,
                italic: 0,
                focused: false
            }
            
        },
        mounted(){
            if(this.$store.state.userInfo.serv){
                this.$refs.editor.innerHTML = this.$store.state.userInfo.serv
            }
            document.addEventListener('click', this.handleClick);
        },
        methods:{
            styletext(){
                if(this.focused){
                    return "2px solid #72E49A"
                }else{
                    return "1px solid lightgrey"
                }
            },
            handleClick(e){
                let self = this
                const textarea = this.$refs.textarea;
                if (textarea.contains(e.target)) {
                    self.focused = true
                }else{
                    self.focused = false
                }
            },
            erase(){
                this.$refs.editor.innerHTML = ""
            },
            addTxt(el){
                document.execCommand('insertText', false, el);
            },
            check(it){
                if(it == 1){
                    return(" bg-dark-subtle")
                }
            },
            makeBold() {
                document.execCommand('bold', false, null);
            },
            makeItalic() {
                document.execCommand('italic', false, null);
            },
            uploadServ(){
                this.$store.commit("uploadServ",this.$refs.editor.innerHTML)
            },
        }
    }
</script>