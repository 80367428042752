<template>
		<div class="container" style="position: relative;overflow: hidden;height: 100%;">
				<div class="border col animate__animated animate__fast animate__slideInRight bg-white" id="modaltemplate" v-if="templatemodal.open" style="height:100vh; width: 50%; position: fixed; right:0; z-index: 100">
						<div class="row p-3 bg-body-secondary m-0">
								<div class="col-1"><button type="button" @click="closemodaltemplate()" class="btn-close"></button></div>
								<div class="col-11"><h5>Template dei sintomi</h5></div>
						</div>
						<div class="row p-3">
								<div class="input-group mb-3">
										<span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
										<input v-model="templatemodal.searchval" type="text" class="form-control" placeholder="ricerca...">
								</div>
								<div class="mb-3" style="max-height: 70vh; overflow-y: scroll">
										<div v-for="template in templatemodal.availabletemp" class="alert alert-dark" role="alert">
												<div class="row">
														<div class="col-10">{{ capitalizeFirstLetter(template.doc.sintomo) }}</div>
														<div @click="applyTemplate(template.id)" class="col-2 text-end"><i class="bi bi-plus-square-fill"></i></div>
												</div>
										</div>
								</div>
						</div>
				</div>
				<div class="border col animate__animated animate__fast animate__slideInRight bg-white" id="modalremed" v-if="modalremed.open" style="height:100vh; width: 50%; position: fixed; right:0; z-index: 100">
						<div class="row p-3 mb-3 bg-body-secondary m-0">
								<div class="col-1"><button type="button" @click="closemodalremed()" class="btn-close"></button></div>
								<div class="col-11"><h5><span v-if="modalremed.editMode">Modifica</span><span v-if="!modalremed.editMode">Aggiungi</span> i rimedi</h5></div>
						</div>
						<div class="row px-4">
								<p class="my-0 py-0 text-body-tertiary">Inserisci il rimedio adatto a:</p>
								<h5>
										<span v-for="el,index in modalremed.remeds[modalremed.curindex]" :key="index">
												{{ el }} <span v-if="index != modalremed.remeds[modalremed.curindex].length-1" class="text-secondary">&nbsp;>&nbsp;</span>
										</span>
								</h5>
						</div><br>
						<div class="row px-4">
								<div class="input-group mb-3">
										<div class="row d-flex align-items-center input-group">
												<div class="col-12">
														<span>
																<textarea :id='"testo-rem-"+modalremed.curindex' :value='checkTextValue(modalremed.curindex)' type="text" class="form-control card" >
																</textarea>
														</span>
												</div>
												<br />
										</div>
								</div>
								<div class="input-group mb-3">
										<p class="col-12 my-0 py-0 text-body-tertiary">Inserisci prodotto</p>
										<span class="input-group-text" id="basic-addon1"><i class="bi bi-prescription2"></i></span>
										<input v-model="createMode.searchprod" type="text" class="form-control" placeholder="Nome del prodotto o codice minsan">
										<!-- modalremed.curremed -->
								</div>
								<div class="px-3 mb-3">
										<div v-if="createMode.searchres && createMode.searchres.length > 0" v-bind:class="'card '+slctrem(createMode.searchres[0].ref['@ref'].id)">
												<div class="card-body">
														<div class="row d-flex align-items-center">
																<div class="col-2">
																		<img v-bind:src="createMode.searchres[0].data.img" alt="" class="img-fluid">
																		<img v-if="createMode.searchres[0].data.img == ''" src="../prod-placeholder.png" alt="" class="img-fluid">
																</div>
																<div class="col-7">
																		<b>{{ createMode.searchres[0].data.name }}</b><br>
																		<span class="text-body-tertiary">Minsan: {{ createMode.searchres[0].data.minsan }}</span><br>
																		<span class="text-body-tertiary">Prodotto da: {{ createMode.searchres[0].data.prod }}</span>
																</div>
																<div class="col-3 text-end" v-if="createMode.searchres[0].ref['@ref'].id != modalremed.curremed">
																		<p @click="modalremed.remname[modalremed.curindex] = createMode.searchres[0].data.name; modalremed.curremed = createMode.searchres[0].ref['@ref'].id; createMode.searchprod = '';" class="text-body-tertiary clk"><u>Seleziona <i class="bi bi-check-lg"></i></u></p>
																</div>
																<div class="col-3 text-end" v-if="createMode.searchres[0].ref['@ref'].id == modalremed.curremed">
																		<p @click="modalremed.remname[modalremed.curindex] = createMode.searchres[0].data.name; modalremed.curremed = ''; createMode.searchres = []" class="text-body-tertiary clk"><i class="bi bi-trash"></i></p>
																</div>
														</div>
												</div>
										</div>
								</div>
						</div>
						<div class="row px-4">
								<div class="input-group mb-3">
										<button v-if="!createMode.rems[modalremed.curindex] || modalremed.editMode" @click="addRem()" class="btn btn-light" style="background-color: lightgray;">Salva</button>
										<button v-if="createMode.rems[modalremed.curindex] && !modalremed.editMode" class="btn btn-primary">Salva<i class="bi bi-check"></i></button>
								</div>
						</div>
						<div class="row px-4">
								<div class="input-group mb-3 animate__animated animate__fadeIn" v-if="createMode.rems.length > modalremed.curindex && !modalremed.editMode">
										<button v-if="modalremed.curindex < modalremed.remeds.length-1" @click="modalremed.curindex++;modalremed.curremed=''" class="btn btn-primary">Prossimo <i class="bi bi-arrow-right"></i></button>
										<button v-if="modalremed.curindex == modalremed.remeds.length-1" @click="closemodalremed()" class="btn btn-primary">Concludi <i class="bi bi-check"></i></button>
								</div>
						</div>
				</div>
				<div class="border col animate__animated animate__fast animate__slideInRight bg-white" id="modaldiram" v-if="modaldiram.open" style="height:100vh; width: 50%; position: fixed; right:0; z-index: 100">
						<div class="row p-3 mb-3 bg-body-secondary m-0">
								<div class="col-1"><button type="button" @click="closemodaldiram()" class="btn-close"></button></div>
								<div class="col-11"><h5><span v-if="modaldiram.editMode">Modifica</span><span v-if="!modaldiram.editMode">Aggiungi</span> una diramazione</h5></div>
						</div>
						<div class="row px-4">
								<span>
										<div class="input-group mb-3">
												<textarea v-model.lazy="modaldiram.cont.domanda" type="text" class="form-control" placeholder="Domanda richiesta*">
												</textarea>
										</div>
										<p v-if='modaldiram.cont.opz.map(e=>e.value).join("").includes(`"`)' class="text-danger">ATTENZIONE: Non è possibile inserire virgolette in questo campo.</p>
										<div class="input-group mb-3" v-for="(op,opi) in modaldiram.cont.opz" :key="opi">
												<input v-model.lazy="op.value" type="text" class="form-control" v-bind:placeholder="'Opzione #'+(opi+1)+'*'">
												<span v-if="modaldiram.cont.opz.length>2" @click="deletediramop(opi)" class="input-group-text" id="basic-addon1"><i class="bi bi-trash"></i></span>
												<span v-if="opi == modaldiram.cont.opz.length-1" @click="adddiramop()" class="input-group-text" id="basic-addon1"><i class="bi bi-plus-square"></i></span>
										</div>
								</span>
						</div>
						<div class="row px-4">
								<div class="input-group mb-3">
										<button v-if="!modaldiram.editMode" @click="addDiram()" class="btn btn-primary">Inserisci <i class="bi bi-plus-square"></i></button>
										<button v-if="modaldiram.editMode" @click="editDiramConf()" class="btn btn-primary">Modifica <i class="bi bi-check"></i></button>
								</div>
						</div>
				</div>
				<div class="border col animate__animated animate__fast animate__slideInRight bg-white" id="modalsynonym" v-if="synonymmodal.open" style="height:100vh; width: 50%; position: fixed; right:0; z-index: 99">
						<div class="row p-3 bg-body-secondary m-0">
								<div class="col-1"><button type="button" @click="closemodalsynonym()" class="btn-close"></button></div>
								<div class="col-11"><h5>Suggerimenti</h5></div>
						</div>
						<div class="px-5" style="max-height: 70vh; overflow-y: scroll">
								<span v-for="(word,index) in synonymmodal.currentval" :key="word">
										<select v-model="synonymmodal.editedval[index]" v-if="synonymmodal.availablevals[index]!=''" class="form-select my-3" size="1" aria-label="size 3 select example">
												<option selected>{{word}}</option>
												<option v-for="syn in synonymmodal.availablevals[index]" v-bind:value="syn">{{syn}}</option>
										</select>
										<input v-model="synonymmodal.editedval[index]" v-if="synonymmodal.availablevals[index].length<1" type="text" class="form-control my-3">
								</span>
						</div>
						<br>
						<div class="px-5">
								<button @click="addSyn()" class="btn btn-primary">Inserisci <i class="bi bi-arrow-right"></i></button>
						</div>
				</div>
				<div v-if="!createMode.open && !editMode.open" class="d-flex align-items-center" style="height:100%">
						<div class="row" style="width:100%">
								<div @click="templatemodal.open = true; createMode.open = true; " class="col text-center">
										<h1><i class="bi bi-file-earmark-plus text-secondary"></i></h1>
										<p>Usa un template</p>
								</div>
								<div @click="createMode.open = true;" class="col text-center">
										<h1><i class="bi bi-pencil-square text-secondary"></i></h1>
										<p>Crea da zero</p>
								</div>
								<div @click="editMode.open = true" class="col text-center">
										<h1><i class="bi bi-gear text-secondary"></i></h1>
										<p>Modifica Esistenti</p>
								</div>
						</div>
				</div>
				<div v-if="editMode.open" style="height: 100%;">
						<div class="row mt-4">
								<div class="col">
										<h3><i @click="editMode.open = false" style="cursor: pointer;" class="bi bi-arrow-left text-body-tertiary"></i></h3>
								</div>
								<div class="col">
										<div class="input-group mb-3">
												<span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
												<input v-model="editMode.searchval" type="text" class="form-control" placeholder="Ricerca..">
										</div>
								</div>
						</div>
						<div class="px-5" style="height: 100%; overflow-y: auto;">
								<br>
								<div class="row">
										<div class="col-4 mb-3" v-for="intent,index in $store.state.intents">
												<div class="card" v-bind:style="'border-bottom: none;'+deactivatedStyleCheck(intent.data.deactivatedFor)">
														<div class="card-header">
																<div class="row">
																		<div class="col-8">
																				<b>{{ intent.data.title }}</b>
																		</div>
																		<div v-if="intent.data.createdBy == 'system'" class="col-4 text-end">
																				<span class="badge bg-dark-subtle">Predefinito</span>
																		</div>
																</div>
																
														</div>
														<div class="card-body fst-italic">
																-"{{ intent.data.phrases[0].value }}"<br>
																-"{{ intent.data.phrases[1].value }}" 
														</div>
														<ul class="list-group list-group-flush border-danger" v-if="editMode.delete == index">
																<li class="list-group-item bg-danger-subtle text-danger"><i class="bi bi-exclamation-diamond"></i>&nbsp;Questa azione è irreversibile, confermi?</li>
														</ul>
														<div v-if="!deactivatedcheck(intent.data.deactivatedFor)" class="card-footer" style="padding: 0; border-top: none">
																<div class="btn-group" style="width:calc(100% + 2px); margin:-1px;">
																		<a v-if="editMode.delete != index" @click="editInt(intent.ref['@ref'].id)" class="btn btn-outline-secondary" style="border-radius: 0px 0px 0px 6px"><i class="bi bi-pencil-square"></i>&nbsp;Modifica</a>
																		<a v-if="editMode.delete != index" @click="editMode.delete = index" class="btn btn-outline-danger" style="border-radius: 0px 0px 6px 0px"><i class="bi bi-trash"></i>&nbsp;Elimina</a>
																		<a v-if="editMode.delete == index" @click="editMode.delete = -1" class="btn btn-outline-danger" style="border-radius: 0px 0px 0px 6px"><i class="bi bi-x-lg"></i>&nbsp;Annulla</a>
																		<a v-if="editMode.delete == index" @click="deleteInt(intent.ref['@ref'].id)" class="btn btn-danger" style="border-radius: 0px 0px 6px 0px"><i class="bi bi-trash"></i>&nbsp;Conferma</a>
																</div>  
														</div>
														<div v-if="deactivatedcheck(intent.data.deactivatedFor)" class="card-footer" style="padding: 0; border-top: none">
																<button class="btn btn-outline-danger" style="width: calc(100% + 2px); border-radius: 0 0 6px 6px; margin:-1px">Contattaci per riattivare l'intento</button>
														</div>
												</div>
										</div>
								</div>
								<br><br><br><br>
						</div>
				</div>
				<div v-if="createMode.open" style="height:100%">
						<div class="row mt-4">
								<div v-if="createMode.editing == ''" class="col">
										<h3><i @click="createMode.open = false" style="cursor: pointer;" class="bi bi-arrow-left text-body-tertiary"></i></h3>
								</div>
								<div v-if="createMode.editing != ''" class="col">
										<h3><i @click="createMode.open = false; editMode.open = true; reset()" style="cursor: pointer;" class="bi bi-arrow-left text-body-tertiary"></i></h3>
								</div>
								<div v-if="createMode.editing == ''" class="col text-end">
										<p @click="templatemodal.open = true" style="cursor: pointer;" class="text-body-tertiary">Usa un template&nbsp;<i class="bi bi-arrow-right text-body-tertiary"></i></p>
								</div>
						</div>
						<div class="px-5" style="height: 100%; overflow-y: auto;">
								<br>
								<div class="mb-3">
										<label class="form-label">Titolo dell'intento:
											<p class="form-text mb-0">Un nome o una breve descrizione che viene presentata al paziente per chiedere conferma della corrispondenza tra i sintomi e la condizione.</p>
										</label>
										<input v-model="createMode.title" class="form-control">
								</div>
								<div class="mb-3">
										<label class="form-label">Frasi di attivazione:
												<p class="form-text">Comincia scrivendo la domanda che farebbe l'utente cercando di essere più specifico possibile, e poi clicca su [<i class="bi bi-stars"></i>] per ricevere dei suggerimenti. Ripeti l'azione e inserisci variazioni finchè non hai inserito almeno 10 esempi, più esempi fornisci, meglio funzionerà il bot.</p>
										</label>
										<div class="progress">
												<div v-bind:class='loaderClass()' v-bind:style="'width: '+createMode.phrases.length+'0%'"></div>
										</div>
										<br>
										<span v-for="(phrase,index) in createMode.phrases" >
												<div class="input-group animate__animated animate__slideInDown animate__faster">
														<input type="text" v-model="phrase.value" class="form-control">
														<button v-if="phrase.value.length>0" @click="getSyn(phrase.value,index)" class="btn btn-outline-secondary" type="button">
																<div v-if="createMode.loadingSyn == index+1" class="spinner-border spinner-border-sm" role="status">
																		<span class="visually-hidden">Loading...</span>
																</div>
																<i v-if="createMode.loadingSyn != index+1" class="bi bi-stars"></i>
														</button>
														<button v-if="createMode.phrases.length>1" @click="deletePhrase(index)" class="btn btn-outline-secondary" type="button"><i class="bi bi-trash"></i></button>
														<button @click="addPhrase()" v-if="index == createMode.phrases.length-1" class="btn btn-outline-secondary" type="button"><i class="bi bi-plus-square"></i></button>
												</div><br>
										</span><br>
										<label class="form-label">
												Diramazioni della conversazione:
												<p class="form-text">Se per rispondere alla domanda al bot occorrono maggiori informazioni, con una diramazione della conversazione è possibile fornire una risposta diversa in base alle specifiche fornite dall'utente su richiesta del bot. <u @click="diramExampleLoad()">Carica un esempio</u><i class="bi bi-stars"></i></p>
										</label>
										<div v-for="(diram,index) in createMode.dirams" :key="diram.domanda" class="alert alert-dark" role="alert">
												<div class="row">
														<div class="col-10">Diramazione: {{ diram.domanda }}</div>
														<div @click="editDiram(index)" class="col-1 text-end"><i class="bi bi-pencil-square"></i></div>
														<div @click="deleteDiram(index)" class="col-1 text-start"><i class="bi bi-trash"></i></div>
												</div>
										</div>
										<button @click="modaldiram.open = true" class="btn btn-primary">Aggiungi    <i class="bi bi-plus"></i></button>
										<br><br><br>
										<span v-if="createMode.dirams.length == 0">
												<div class="row d-flex align-items-center border rounded-top p-2 bg-secondary-subtle">
														<div class="col-5 text-end">Suggerisci un prodotto</div>
														<div class="col-2 text-center">
																<h3 id="toggler" class="m-0" @click="createMode.remprod ^= true" style="cursor: pointer;">
																		<i v-if="createMode.remprod" class="bi bi-toggle-off"></i>
																		<i v-if="!createMode.remprod" class="bi bi-toggle-on"></i>
																</h3>
														</div>
														<div class="col-5 text-start">Rispondi con una frase</div>
												</div>
												<div class="row border-bottom border-end border-start rounded-bottom p-2">
														<span v-if="createMode.remprod">
																<label class="form-label">Rimedi proposti:</label><br>
																<p class="form-text">Ricerca il rimedio da proporre una volta identificato il problema dell'utente e aggiungilo alla lista.</p>
																<div class="row px-2">
																		<div class="input-group mb-3 p-0">
																				<span class="input-group-text" id="basic-addon1"><i class="bi bi-prescription2"></i></span>
																				<input v-model="createMode.searchprod" type="text" class="form-control" placeholder="Nome del prodotto o codice minsan">
																		</div><br>
																		<div v-if="createMode.searchres && createMode.searchres.length !== 0" v-bind:class="'card mb-3 '+slctrem(createMode.searchres[0].ref['@ref'].id)">
																				<div class="card-body">
																						<div class="row d-flex align-items-center">
																								<div class="col-2">
																										<img v-bind:src="createMode.searchres[0].data.img" alt="" class="img-fluid">
																										<img v-if="createMode.searchres[0].data.img == ''" src="../prod-placeholder.png" alt="" class="img-fluid">
																								</div>
																								<div class="col-7">
																										<b>{{ createMode.searchres[0].data.name }}</b><br>
																										<span class="text-body-tertiary">Minsan: {{ createMode.searchres[0].data.minsan }}</span><br>
																										<span class="text-body-tertiary">Prodotto da: {{ createMode.searchres[0].data.prod }}</span>
																								</div>
																								<div class="col-3 text-end" v-if="!createMode.rems.includes(createMode.searchres[0].ref['@ref'].id)">
																										<p @click="checkPhrase() === 2 ? createMode.rems = [] : false, createMode.rems.push(createMode.searchres[0].ref['@ref'].id), blockToggle(true)" class="text-body-tertiary clk"><u>Seleziona <i class="bi bi-check-lg"></i></u></p>
																								</div>
																								<div class="col-3 text-end" v-else>
																										<p class="text-body-tertiary"><u>Aggiunto<i class="bi bi-check-lg"></i></u></p>
																								</div>
																						</div>
																				</div>
																		</div>
																</div>
																<p style="font-size: 12px; color: gray; text-align: right;">Per poter rispondere con una frase dopo aver selezionato un prodotto, prima eliminare tutti i prodotto selezionati</p>
																<div v-if="createMode.rems.length !== 0">
																	<label v-if="checkPhrase() !== 2" class="form-label">Rimedi selezionati:</label>
																	<div v-for="(rems) in createMode.rems">
																		<div v-if="listProducts(rems) !== false" class="remedyListItem">
																			{{ listProducts(rems) }}
																			<p @click="createMode.rems.splice(index,1), createMode.rems.length === 0 ? blockToggle(false) : ''" class="text-body-tertiary clk"><i class="bi bi-trash"></i></p>
																			</div>
																		</div>
																</div>
														</span>
														<span v-if="!createMode.remprod">
																<label class="form-label">Risposta:</label><br>
																<p class="form-text">Inserisci la risposta da fornire all'utente.</p>
																<div class="row px-2">
																		<div class="input-group mb-3 p-0">
																				<span class="input-group-text" id="basic-addon1"><i class="bi bi-chat-left-quote"></i></span>
																				<textarea rows="5" v-model="createMode.rems[0]" type="text" class="form-control" placeholder="Inserici la risposta"></textarea>
																		</div><br>
																</div>
														</span>
												</div>
										</span>                    
										<span v-if="createMode.dirams.length > 0">
											<label class="form-label">Rimedi proposti:</label><br>
											<p class="form-text">Carica i singoli rimedi da proporre una volta identificato il problema dell'utente.</p>
											<div v-for="(remed,index) in createMode.rems" :key="index" class="alert alert-dark" role="alert">
														<div class="row">
																<div class="col-10">
																		<span v-for="el,i in remed.for" :key="i">
																				{{ el }} <span v-if="i != remed.for.length-1">&nbsp;>&nbsp;</span>
																		</span>
																				<!-- <b>{{ getremfromid(remed.res).data.name }}</b> -->
																</div>
																<div @click="modalremed.open = true; createMode.searchprod = modalremed.remname[index]; modalremed.curindex = index; modalremed.curremed = remed.res; modalremed.editMode = true" class="col-1 text-end">
																		<i class="bi bi-pencil-square"></i>
																</div>
																<div @click="deleteDiramRemed(index)" class="col-1 text-start"><i class="bi bi-trash"></i></div>
														</div>
												</div>
												<button v-if="createMode.rems.length != modalremed.remeds.length" @click="modalremed.open = true" class="btn btn-primary">Aggiungi    <i class="bi bi-plus"></i></button><br>
										</span>
										<br><br>
										<div class="input-group mb-3">
												<div v-if="createMode.editing == ''" @click="finalize()" class="btn btn-primary d-flex align-items-center">Aggiungi al database&nbsp;&nbsp;<h4 class="m-0"><i class="bi bi-cloud-arrow-up"></i></h4></div>
												<div v-if="createMode.editing != '' & !createMode.system" @click="update()" class="btn btn-primary d-flex align-items-center">Aggiorna&nbsp;&nbsp;<h4 class="m-0"><i class="bi bi-cloud-arrow-up"></i></h4></div>
												<div v-if="createMode.editing != '' & createMode.system" @click="deactivateInt(createMode.editing);finalize('bypass')" class="btn btn-primary d-flex align-items-center">Aggiorna&nbsp;&nbsp;<h4 class="m-0"><i class="bi bi-cloud-arrow-up"></i></h4></div>
										</div>
								</div>
								<br><br><br><br>
								
						</div>
				</div>
		</div>
		
</template>

<style>
		.selectcard{
				border-color: rgb(51, 232, 148);
				outline: 10px solid rgba(51, 232, 148, 0.2)
		}

		.remedyListItem {
				border: solid 1px #cecece;
				border-radius: 10px;
				display: inline-flex;
				width: 100%;
				justify-content: space-between;
				padding: 18px 20px 0;
				line-height: 1.2em;
				margin-bottom: 10px;
				font-weight: bold;
		}
</style>

<script>
		import template from '../sintomi-semplici.json'
		import axios from "axios"
		import netlifyIdentity from 'netlify-identity-widget';

		export default{
				data(){
						return{
								synonymmodal:{
										open: false,
										editedval: [],
										currentval: [],
										availablevals: []
								},
								modaldiram: {
										editMode: false,
										open: false,
										cont: {
												domanda: "",
												opz: [{value:""},{value:""}],
												sp: ""
										},
										editIndex: 0
								},
								modalremed:{
										open: false,
										branches: false,
										curremed: "",
										note: "",
										remeds: [],
										curindex: 0,
										editMode: false,
										remname: []
								},
								templates: template,
								templatemodal: {
										searchval: "",
										open: false,
										availabletemp: []
								},
								editMode:{
										open: false,
										searchres: [],
										searchval:"",
										delete: -1
								},
								createMode: {
										open: false,
										searchprod: "",
										searchres: [],
										remprod: '',
										title:"",
										phrases:[
												{
												value: ""
												}
										],
										dirams: [],
										loadingSyn: 0,
										rems: [],
										editing: "",
										system: false,
								}
						}
				},
				mounted(){
						if(this.$store.state.intents.length == 0){
								this.$store.dispatch("getIntents")
						};
						if(this.$store.state.inventory.length == 0){
								this.$store.dispatch("getDrugs")
						}
				},
				methods:{
						checkToggle() {
							let regex = /^[0-9]+$/;
							if(this.createMode.rems.length !== 0){
								if(regex.test(this.createMode.rems[0])){
									this.createMode.remprod = true;
									this.blockToggle(true)
								} else {
									this.createMode.remprod = false;
									this.blockToggle(false)
								}								
							} else {
								this.createMode.remprod = true;
								this.blockToggle(false)
							}
						},
						blockToggle(val) {
							let toggler = document.getElementById('toggler');
							let interval = setInterval(function () {
								if (!this.toggler) {} 
								else {
									if(!val){
										this.toggler.style.opacity = '1'
										this.toggler.style.pointerEvents = 'all';
									}	else {
										this.toggler.style.opacity = '0.5'
										this.toggler.style.pointerEvents = 'none';
									}
									clearInterval(interval);
								}
							}, 100);
						},
						checkPhrase(){
								let regex = /^[0-9]+$/;
								if(this.createMode.rems.length === 0) {
										return 0;
								}else if(this.createMode.rems.length >= 1 && regex.test(this.createMode.rems[0])){
										return 1;
								} else {
										return 2;
								}
						},
						listProducts(value){
								let self = this;
								self.createMode.listprod = this.$store.state.inventory
								let regex = /^[0-9]+$/;
								if(value !== ''){
										if(regex.test(value)) {
												let prodFind = self.createMode.listprod.filter(x => x.ref['@ref'].id === value);
												let name = prodFind.length > 0 ? prodFind[0].data.name : '';
												return name;
										} else {
												return false
										}
								}
						},
						checkTextValue(index) {
								let value
								if(this.createMode.rems[index]){
										value = this.createMode.rems[index].note
								} else {
										value = ""
								}
								return value
						},
						slctrem(id){
								if (id == this.createMode.rems[0] || id == this.modalremed.curremed){
										return "selectcard"
								}
						},
						slctremList(id,i){
								if (id == this.createMode.rems[i] || id == this.modalremed.curremed){
										return "selectcard"
								}
						},
						getremfromid(id){
								return this.$store.state.inventory.find(el => el.ref["@ref"].id == id)
						},
						curId(){
								return netlifyIdentity.currentUser().id
						},
						deactivateInt(id){
								let el = this.$store.state.intents.find(el => el.ref['@ref'].id == id).data
								if(el.deactivatedFor){
										el.deactivatedFor.push(netlifyIdentity.currentUser().id)
								}else{
										el["deactivatedFor"] = [netlifyIdentity.currentUser().id]
								}
								this.$store.commit("deactivate",{
										id: id,
										doc: el
								})
						},
						deactivatedStyleCheck(arr){
								if(arr){
										if(arr.includes(netlifyIdentity.currentUser().id)){
												return "; opacity: 0.5; pointer-events: none"
										}
								}
						},
						deactivatedcheck(arr){
								if(arr){
										if(arr.includes(netlifyIdentity.currentUser().id)){
												return true
										}
								}
						},
						deleteInt(id){
								this.$store.commit("delete",id)
								this.editMode.delete = -1
						},
						editInt(id){
								this.editMode.open = false
								this.createMode.open = true
								this.createMode.editing = id
								let elem = this.$store.state.intents.find(el => el.ref['@ref'].id == id).data
								this.createMode.phrases = elem.phrases
								this.createMode.dirams = elem.dirams
								this.createMode.rems = elem.rems
								this.createMode.title = elem.title
								if(elem.createdBy == "system"){
									this.createMode.system = true
								}
								this.checkToggle()
						},
						addSyn(){
								this.createMode.phrases.push({value:this.synonymmodal.editedval.join(" ")})
						},
						adddiramop(){
								this.modaldiram.cont.opz.push({value:""})
						},
						addRem(){
								let textarea = document.getElementById("testo-rem-"+this.modalremed.curindex);
								this.modalremed.note = textarea.value
								this.createMode.rems[this.modalremed.curindex] = {
										for: this.modalremed.remeds[this.modalremed.curindex],
										res: this.modalremed.curremed,
										note: this.modalremed.note
								}
								if(this.modalremed.editMode == true){
										this.closemodalremed()
								}
								this.modalremed.editMode = false
						},
						addDiram(){
								let cur = this
								function iscomplete(){
										console.log(cur.modaldiram.cont)
										if(cur.modaldiram.cont.domanda == "" ){
												return false
										}
										cur.modaldiram.cont.opz.forEach(el=>{
												if(el.value == ""){
														return false
												}
										})
										return true
								}
								if(iscomplete()){
										this.createMode.dirams.push(this.modaldiram.cont);
										this.closemodaldiram()
										this.modaldiram.cont = {
												domanda: "",
												opz: [{value:""},{value:""}],
												sp: ""
										}
								}else{
										this.$store.commit("addErr","Aggiungi tutti i dati richiesti prima di proseguire")
								}
						},
						deleteDiram(index){
								if(this.createMode.dirams.length == 1){
										this.createMode.dirams = []
										this.modalremed.remeds = []
								}else{
										this.createMode.dirams.splice(index,1)
								}
								this.createMode.rems = []
						},
						deleteDiramRemed(index){
								this.createMode.rems.splice(index,1)
						},
						editDiramConf(){
								let cur = this
								function iscomplete(){
										console.log(cur.modaldiram.cont)
										if(cur.modaldiram.cont.domanda == "" ){
												return false
										}
										/* if(cur.modaldiram.cont.sp == "" ){
												return false
										} */
										cur.modaldiram.cont.opz.forEach(el=>{
												if(el.value == ""){
														return false
												}
										})
										return true
								}
								if(iscomplete()){
										this.createMode.dirams[this.modaldiram.editIndex] = this.modaldiram.cont
										this.closemodaldiram()
										this.modaldiram.cont = {
												domanda: "",
												opz: [{value:""},{value:""}],
												sp: ""
										}
										this.modaldiram.editMode = false
								}else{
										this.$store.commit("addErr","Aggiungi tutti i dati richiesti prima di proseguire")
								}
						},
						deletediramop(opi){
								this.modaldiram.cont.opz.splice(opi,1)
						},
						diramExampleLoad(){
								this.modaldiram.open = true
								this.modaldiram.cont.domanda = "Hai la tosse secca o grassa?"
								this.modaldiram.cont.sp = "La tosse grassa è spesso accompagnata da produzione di muco o catarro, mentre la tosse secca è legata a un'irritazione o infiammazione delle vie respiratorie"
								this.modaldiram.cont.opz[0].value = "Tosse Secca"
								this.modaldiram.cont.opz[1].value = "Tosse Grassa"
						},
						editDiram(index){
								let curmod = JSON.parse(JSON.stringify(this.createMode.dirams[index]))
								this.modaldiram.open = true
								this.modaldiram.editMode = true
								this.modaldiram.cont = curmod
								this.modaldiram.editIndex = index
						},
						reset(){
								this.createMode = {
										open: true,
										title:"",
										phrases:[
												{
												value: ""
												}
										],
										dirams: [],
										loadingSyn: 0,
										rems: [],
										editing: ""
								}
								this.modaldiram = {
										editMode: false,
										open: false,
										cont: {
												domanda: "",
												opz: [{value:""},{value:""}],
												sp: ""
										},
										editIndex: 0
								}
								this.modalremed = {
										open: false,
										branches: false,
										curremed: "",
										note: "",
										remeds: [],
										curindex: 0,
										editMode: false,
										remname: []
								}
						},
						update(){
								let cur = this
								function checkvals(){
										if(cur.createMode.phrases.length<10){
												cur.$store.commit("addErr","Aggiungi almeno 10 frasi di attivazione per continuare")
												return false
										}else{
												cur.createMode.phrases.forEach((el,index)=>{
														if(el.value == ""){
																cur.$store.commit("addErr","La "+(index+1)+"° frase di attivazione è vuota")
																return false
														}
												})
										}
										if(cur.createMode.title == ""){
												cur.$store.commit("addErr","Aggiungi un titolo all'intento")
												return false
										}
										if(cur.createMode.rems.length == 0 || cur.createMode.rems[0] == ""){
												cur.$store.commit("addErr","Aggiungi un rimedio")
												return false
										}
										return true
								}
								if(checkvals()){
										this.$store.commit("updateIntent",{
												id: this.createMode.editing,
												doc: {
														title: this.createMode.title,
														phrases: this.createMode.phrases,
														dirams: this.createMode.dirams,
														rems: this.createMode.rems,
												}
										})
								}
						},
						finalize(bypass){
								let cur = this
								function checkvals(){
										if(bypass=="bypass"){
												return true
										}else{
												if(cur.createMode.phrases.length<10){
														cur.$store.commit("addErr","Aggiungi almeno 10 frasi di attivazione per continuare")
														return false
												}else{
														cur.createMode.phrases.forEach((el,index)=>{
																if(el.value == ""){
																		cur.$store.commit("addErr","La "+(index+1)+"° frase di attivazione è vuota")
																		return false
																}
														})
												}
												if(cur.createMode.title == ""){
														cur.$store.commit("addErr","Aggiungi un titolo all'intento")
														return false
												}
												if(cur.createMode.rems.length == 0 || cur.createMode.rems == []){
														cur.$store.commit("addErr","Aggiungi un rimedio")
														return false
												}
												return true
										}
										
								}
								if(checkvals()){
										this.$store.commit("uploadIntent",{
														title: this.createMode.title,
														phrases: this.createMode.phrases,
														dirams: this.createMode.dirams,
														rems: this.createMode.rems,
														createdBy: netlifyIdentity.currentUser().id
										})
										this.createMode = {
												open: true,
												title:"",
												phrases:[
														{
														value: ""
														}
												],
												dirams: [],
												loadingSyn: 0,
												rems: [],
												editing: "",
												system: false
										}
										this.modaldiram = {
												editMode: false,
												open: false,
												cont: {
														domanda: "",
														opz: [{value:""},{value:""}],
														sp: ""
												},
												editIndex: 0
										}
										this.modalremed = {
												open: false,
												branches: false,
												curremed: "",
												note: "",
												remeds: [],
												curindex: 0,
												editMode: false,
												remname: []
										}
								}
						},
						getSyn(val,index){
								this.createMode.loadingSyn = index+1
								this.synonymmodal.editedval = []
								this.synonymmodal.currentval = val.split(/[^\w\s]+|[\s]+/g).filter(Boolean)

								axios.post(".netlify/functions/get-syn",{file: this.synonymmodal.currentval}).then(res=>{
										this.synonymmodal.availablevals = res.data
										res.data.forEach((el,index)=>{
												if(el!==""){
														this.synonymmodal.editedval.push(el[0])
												}else{
														this.synonymmodal.editedval.push(this.synonymmodal.currentval[index])
												}
										})
										this.synonymmodal.open = true
										this.createMode.loadingSyn = false
								})
						},
						loaderClass(){
								if(this.createMode.phrases.length>4){
								if(this.createMode.phrases.length>9){
										return "progress-bar bg-success"
								}else{
										return "progress-bar bg-warning"
								}
								}else{
								return "progress-bar bg-danger"
								}
						},
						deletePhrase(index) {
								this.createMode.phrases.splice(index,1)
						},
						addPhrase(){
								this.createMode.phrases.push({value:""})
						},
						applyTemplate(i){
								this.createMode.phrases = []
								let doc = []
								this.templates[i].frasi.forEach(el=>{
										doc.push({
												value: el
										})
								})
								this.createMode.title = this.templates[i].sintomo
								this.createMode.phrases = doc
								this.closemodaltemplate();
						},
						capitalizeFirstLetter: function(string) {
								return string.charAt(0).toUpperCase() + string.slice(1);
						},
						closemodaltemplate(){
								document.getElementById("modaltemplate").classList.add("animate__slideOutRight")
								setTimeout(() => {
										this.templatemodal.open = false
										document.getElementById("modaltemplate").classList.add("animate__slideOutRight")
								}, 800);
						},
						closemodalremed(){
								document.getElementById("modalremed").classList.add("animate__slideOutRight")
								setTimeout(() => {
										this.modalremed.open = false
										document.getElementById("modalremed").classList.add("animate__slideOutRight")
								}, 800);
						},
						closemodaldiram(){
								
								document.getElementById("modaldiram").classList.add("animate__slideOutRight")
								setTimeout(() => {
										this.modaldiram.open = false
										document.getElementById("modaldiram").classList.add("animate__slideOutRight")
										this.modaldiram.cont = {
												domanda: "",
												opz: [{value:""},{value:""}],
												sp: ""
										}
										this.modaldiram.editMode = false
								}, 800);
						},
						closemodalsynonym(){
								document.getElementById("modalsynonym").classList.add("animate__slideOutRight")
								setTimeout(() => {
										this.synonymmodal.open = false
										document.getElementById("modalsynonym").classList.add("animate__slideOutRight")
								}, 800);
						}
				},
				computed: {
						templatemodalsearch() {
								return this.templatemodal.searchval;
						},
						diram() {
								return this.createMode.dirams.length;
						},
						searchIntents(){
								return this.editMode.searchval
						},
						searchProducts(){
								return this.createMode.searchprod
						},
				},
				watch:{
						searchIntents(value){
								let self = this
								self.editMode.searchres = this.$store.state.intents
								self.editMode.searchres.forEach(elem=>{
										if(elem.data.title.toLowerCase().includes(value)){
												self.editMode.searchres.splice(self.editMode.searchres.indexOf(elem),1)
												self.editMode.searchres.unshift(elem)
										}
								})
						},
						diram(value){
								if(value > 0){
										this.modalremed.branches = true
								}else{
										this.modalremed.branches = false
								}
								if(value > 0){
										if(this.createMode.editing == ''){
												this.createMode.rems = []
										}
										let options = []
										this.createMode.dirams.forEach(branch=>{
												let option = []
												branch.opz.forEach(el=>{
														option.push(el.value)
												})
												options.push(option)
										})
										function cartesian(args) {
												var r = [], max = args.length-1;
												function helper(arr, i) {
														for (var j=0, l=args[i].length; j<l; j++) {
														var a = arr.slice(0);
														a.push(args[i][j]);
														if (i==max)
																r.push(a);
														else
																helper(a, i+1);
														}
												}
												helper([], 0);
												return r;
										}
										this.modalremed.remeds = cartesian(options)
								}
						},
						templatemodalsearch(value){
								let curtemp = []
								if(value.length>2){
										this.templates.forEach((el,index)=>{
												let match = false
												let matchval = 0
												el.frasi.forEach(fr=>{
														if(fr.includes(value.toLowerCase())){
																match = true
																matchval++
														}
												})
												if(match){
														curtemp.push({
																doc: el,
																dist: matchval,
																id: index
														})
														this.templatemodal.availabletemp = curtemp.sort(e=>e.dist).reverse()
												}
										})
								}else{
										this.templatemodal.availabletemp = []
								}
						},
						searchProducts(value){
								let self = this
								self.createMode.searchres = this.$store.state.inventory
								var regex = /^[0-9]+$/;
								if(value == ""){
										self.createMode.searchres.forEach(elem=>{
												if(elem.ref['@ref'].id == self.createMode.rems[0]){
														self.createMode.searchres.splice(self.createMode.searchres.indexOf(elem),1)
														self.createMode.searchres.unshift(elem)
												}
										})
								}else{
										if(regex.test(value)){
												self.createMode.searchres.forEach(elem=>{
														if(elem.data.minsan.toLowerCase().includes(value)){
																self.createMode.searchres.splice(self.createMode.searchres.indexOf(elem),1)
																self.createMode.searchres.unshift(elem)
														}
												})
										}else{
												self.createMode.searchres.forEach(elem=>{
														if(elem.data.name.toLowerCase().includes(value)){
																self.createMode.searchres.splice(self.createMode.searchres.indexOf(elem),1)
																self.createMode.searchres.unshift(elem)
														}
												})
										}
								}
						}
				}
		}

</script>