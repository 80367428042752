<template>
    <div class="container" style="height: 100%;">
        <br>
        <h6>Chat con il supporto tecnico di Farmadoc</h6>
        <hr>
        <div style="overflow-x: auto; width: 100%;">
            <div class="row" v-bind:style="'width: '+$store.state.tickets.length*400+'px;'">
                <div class="col-6" style="width: 400px;" v-for="item in $store.state.tickets">
                    <div class="card clk hvr" @click='$store.commit("setTicket",item.ref["@ref"].id)'>
                        <div class="card-body">
                           <b>ticket #{{ item.ref["@ref"].id }}</b><br>
                            <i class="text-body-tertiary">{{ date(item.ts) }}</i>
                        </div>
                    </div>
                </div>
            </div><br>
        </div>
        <div v-if="$store.state.loaded" class="p-3" style="height: 60%; box-sizing: border-box; overflow-y: auto; display:flex;align-items: flex-end; flex-direction: column-reverse;">
            <div style="display: block; position: relative; width:100%; box-sizing: border-box;">
                <div v-if="$store.state.chatTicket != ''" v-for="(el,index) in curticket().data.chat">
                    <div class="text-center" style="width: 100%;" v-if="el.type == 'update'">
                        <p>{{ el.msg }}</p>
                        <hr>
                    </div>
                    <div class="text-center" style="width: 100%;" v-if="el.type == 'enddeclined'">
                        <p>Hai rifiutato la chiusura del ticket</p>
                        <hr>
                    </div>
                    <div class="text-center" style="width: 100%;" v-if="el.type == 'endapproved'">
                        <p>Hai approvato la chiusura del ticket</p>
                        <hr>
                    </div>
                    <div class="text-center" style="width: 100%;" v-if="el.type == 'askend'">
                        <p>Siamo riusciti a risolvere il tuo problema?</p>
                        <hr>
                        <div class="row" v-if="index == curticket().data.chat.length-1">
                            <div @click="$store.commit('approveend')" class="col text-end"><button class="btn btn-primary"><i class="bi bi-check-lg"></i></button></div>
                            <div @click="$store.commit('disapproveend')" class="col text-start"><button class="btn btn-danger"><i class="bi bi-x-lg"></i></button></div>
                        </div>
                    </div>
                    <div class="text-end mb-3 animate__animated animate__slideInUp animate__faster" v-if="el.type == 'sent'">
                        <div class="card bg-body-secondary" style="max-width: 50%;border-radius: 10px 10px 0 10px; display: inline-block;">
                            <div class="card-body">
                                {{ el.msg }}
                            </div>
                        </div>
                    </div>
                    <div class="text-start mb-3 animate__animated animate__slideInUp animate__faster" v-if="el.type == 'received'">
                        <div class="card bg-body-secondary" style="max-width: 50%;border-radius: 0px 10px 10px 10px; display: inline-block;">
                            <div class="card-body">
                                {{ el.msg }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-group mb-3">
            <input v-on:keyup.enter="send()" v-model="message" type="text" class="form-control" placeholder="Scrivi il messaggio">
            <span @click="send()" class="input-group-text p-0"><button style="border-radius: 0px 6px 6px 0px; margin: -1px" class="btn btn-lg btn-outline-primary"><i class="bi bi-send"></i></button></span>
        </div>
    </div>
</template>

<style>
.clk{
    cursor: pointer;
}
.hvr:hover{
    background-color: lightgray;
}
</style>

<script>

    export default{
        data(){
            return{
                message: "",
            }
        },
        mounted(){
            this.$store.commit("getTickets")
            if(this.$store.state.message != ""){
                this.message = this.$store.state.message
                this.send()
            }
            setInterval(() => {
                this.$store.commit("timerUpdate")
            }, 1000);
            /* setInterval(() => {
                this.$store.commit("updateTick")
            }, this.$store.state.sinceLast*2); */

        },
        methods:{
            curticket(){
                
                return this.$store.state.tickets.find(el => el.ref["@ref"].id == this.$store.state.chatTicket)
            },
            date: function(el){
                return new Date(el/1000).toLocaleTimeString('it-IT',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
            },
            send(){
                if(this.message != ""){
                    this.$store.commit("sendMsg",this.message)
                    this.message = ""
                }
            }
        }
    }
</script>