import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Intelligence from '../views/Intelligence.vue'
import Intents from '../views/Intents.vue'
import Settings from '../views/Settings.vue'
import Support from '../views/Support.vue'
import Inventory from '../views/Inventory.vue'
import Services from '../views/Services.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/intelligence',
    name: 'Intelligence',
    component: Intelligence
  },
  {
    path: '/intents',
    name: 'Intents',
    component: Intents
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
