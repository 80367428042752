<template>
  <div
    style="
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      max-width: 600px;
      padding: 10px;
      pointer-events: none;
      z-index: 10000;
    "
  >
    <span
      v-for="(element, index) in $store.state.alerts"
      :key="index"
      style="pointer-events: all"
    >
      <div
        v-bind:class="
          'alert alert-' +
          element[1] +
          ' d-flex align-items-center alert-dismissible animate__animated animate__slideInRight animate__faster'
        "
        role="alert"
      >
        <i class="bi bi-exclamation-triangle-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="">
          {{ element[0] }}
        </div>
        <button
          @click="$store.commit('removeAlert', index)"
          class="btn-close"
        ></button>
      </div>
    </span>
  </div>
  <div
    v-if="!loggedIn"
    style="
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
    "
  >
    <div class="animate__animated animate__zoomIn" style="text-align: center">
      <div class="fs-1">
        <b>Farmadoc</b>&nbsp;<i
          class="bi bi-chat-left-heart-fill text-secondary"
        ></i>
      </div>
      <button
        @click="login()"
        type="button"
        class="btn btn-link text-secondary"
      >
        Accedi o crea un account
      </button>
    </div>
  </div>
  <div
    v-if="loggedIn && !$store.state.userInfo.activeSub && $store.state.userInfo"
    style="
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
    "
  >
    <div class="animate__animated animate__zoomIn" style="text-align: center">
      <div class="fs-1 text-secondary">
        <b>Attiva il tuo abbonamento per iniziare</b>
      </div>
      <button @click="logout()" type="button" class="btn btn-link text-muted">
        Oppure cambia account
      </button>
    </div>
  </div>
  <div class="row" style="width: 100vw">
    <div class="col-3">
      <nav
        v-if="loggedIn && $store.state.userInfo.activeSub"
        class="nav flex-column bg-secondary py-3"
        style="width: 100%; height: 100vh"
      >
        <div class="ticks d-flex flex-column">
          <span v-for="item in 60" class="flex-fill"
            ><span :class="'tickpiece ' + tickclass(item)"></span
          ></span>
        </div>
        <div class="p-5 fs-4">
          <b>Farmadoc</b>&nbsp;<i
            class="bi bi-chat-left-heart-fill text-primary"
          ></i>
        </div>
        <router-link
          @click="ticks = 1"
          active-class="active"
          to="/"
          class="nav-link text-black px-5 py-3"
          href="#"
          ><img
            src="https://cdn-icons-png.flaticon.com/512/2732/2732589.png"
            alt=""
          />
          Dashboard
        </router-link>
        <router-link @click="ticks=2" active-class="active" to="/intelligence" class="nav-link text-black px-5 py-3" href="#"><img src="https://cdn-icons-png.flaticon.com/512/2297/2297129.png" alt="">Intelligence</router-link>
        <router-link
          @click="ticks = 3"
          active-class="active"
          to="/intents"
          class="nav-link text-black px-5 py-3"
          href=""
          ><img
            src="https://cdn-icons-png.flaticon.com/512/1757/1757713.png"
            alt=""
          />Intenti</router-link
        >
        <router-link
          @click="ticks = 3"
          active-class="active"
          to="/inventory"
          class="nav-link text-black px-5 py-3"
          href=""
          ><img
            src="https://cdn-icons-png.flaticon.com/512/10277/10277034.png"
            alt=""
          />Farmaci</router-link
        >
        <router-link @click="ticks=3" active-class="active" to="/services" class="nav-link text-black px-5 py-3" href=""><img src="https://cdn-icons-png.flaticon.com/512/3207/3207536.png " alt="">Servizi</router-link>
        <router-link
          @click="ticks = 4"
          active-class="active"
          to="/support"
          class="nav-link text-black px-5 py-3"
          href="#"
          ><img
            src="https://cdn-icons-png.flaticon.com/512/954/954528.png"
            alt=""
          />Supporto</router-link
        >
        <router-link
          @click="ticks = 5"
          active-class="active"
          to="/settings"
          class="nav-link text-black px-5 py-3"
          href="#"
          ><img
            src="https://cdn-icons-png.flaticon.com/512/954/954532.png"
            alt=""
          />Impostazioni</router-link
        >
        <a
          href="#"
          @click="logout()"
          class="nav-link text-black px-5 py-5"
          style="bottom: 0; position: absolute"
          ><img
            src="https://cdn-icons-png.flaticon.com/512/954/954497.png"
            alt=""
          />Logout</a
        >
      </nav>
    </div>
    <div class="col-9" style="height: 100vh; overflow-y: auto">
      <router-view v-if="loggedIn && $store.state.userInfo.activeSub" />
    </div>
  </div>
</template>

<style>
.clk {
  cursor: pointer;
}
.nav {
  border-left: 2px solid black;
  position: relative;
}
.nav:hover > .ticks {
  width: 10%;
}
.ticks {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  transition: 0.5s ease;
}
.tickpiece {
  background-color: black;
  position: absolute;
  width: 50%;
  height: 2px;
  z-index: 9;
  transition: 0.5s ease;
}
.tickpiece.big {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 9;
  transition: 0.5s ease;
}
.nav-link {
  background-color: rgba(255, 255, 255, 0);
  z-index: 10;
  border-right: 0px solid black;
  transition: 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
}
.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-right: 3px solid black;
}
.nav-link > img {
  height: 30px;
  margin-right: 8px;
}
* {
  box-sizing: border-box;
}
.welcome {
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>

<script>
import netlifyIdentity from "netlify-identity-widget";

export default {
  data() {
    return {
      ticks: 1,
      loggedIn: false,
    };
  },
  created() {
    netlifyIdentity.init({
      namePlaceholder: "Username",
      locale: "de",
    });
    const user = netlifyIdentity.currentUser();
    if (user) {
      this.loggedIn = true;
      this.$store.commit("getUser", user.id);
    } else {
      netlifyIdentity.open();
    }
    netlifyIdentity.on("login", (user) => {
      this.loggedIn = true;
      this.$store.commit("getUser", user.id);
    });
    netlifyIdentity.on("logout", (user) => (this.loggedIn = false));

    if (!localStorage.getItem("farmadoc.data")) {
      localStorage.setItem("farmadoc.data", JSON.stringify([]));
    } else {
      this.$store.commit("checkLogs");
    }
  },
  methods: {
    tickclass: function (item) {
      if ((item - this.ticks) % 4 == 0) {
        return "big";
      } else {
        return "";
      }
    },
    logout: function () {
      netlifyIdentity.logout();
    },
    login: function () {
      netlifyIdentity.open();
    },
  },
};
</script>
